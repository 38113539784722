import React from 'react'
import styled from 'styled-components'
import Gravatar from 'react-gravatar'
import SEO from '../components/SEO'
import { graphql } from 'gatsby'
import LayoutWithQuery from '../components/Layout'
import Footer from '../components/Footer'

const Container = styled.div`
  padding-top: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
`

const AboutContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${({ theme }) => theme.maxwidth};

  p {
    text-align: left;
    margin-bottom: 1rem;
  }
  margin: 0 1rem;
`

const GravatarContainer = styled.div`
  padding-top: 2rem;
  padding-bottom: 2rem;
  display: flex;
  justify-content: center;
`

const Markdown = styled.span`
  line-height: 150%;
  max-width: 500px;
  margin: 0 auto;
`

const AboutHeader = styled.h1`
  text-align: center;
`

const About = ({
  data: {
    site: {
      pathPrefix,
      siteMetadata: {
        url,
        siteName,
        social: { gravatar, twitter },
        author: { name },
      },
    },
    about: { html, excerpt },
  },
}) => (
  <LayoutWithQuery isHomePage={false}>
    <Container>
      <SEO
        title={`About - ${name}`}
        url={`${url}${pathPrefix}`}
        siteName={siteName}
        description={excerpt}
        social={{
          twitter,
          gravatar,
        }}
      />
      <AboutContainer>
        <AboutHeader>About me</AboutHeader>
        <GravatarContainer>
          <Gravatar email={gravatar} size={150} />
        </GravatarContainer>
        <Markdown dangerouslySetInnerHTML={{ __html: html }} />
        <Footer />
      </AboutContainer>
    </Container>
  </LayoutWithQuery>
)

export default About

export const query = graphql`
  query AboutQuery {
    site {
      siteMetadata {
        author {
          name
        }
        social {
          twitter
          instagram
          facebook
          gravatar
          linkedIn
          stackOverflow
          github
          gitlab
        }
      }
    }
    about: markdownRemark(frontmatter: { type: { eq: "about" } }) {
      fields {
        slug
      }
      html
      excerpt
    }
  }
`
